// extracted by mini-css-extract-plugin
export var additionalPadding = "CartReservationComplete__additionalPadding__RzoRR";
export var button = "CartReservationComplete__button__DBlWZ";
export var buttonContainer = "CartReservationComplete__buttonContainer__Uqkv2";
export var buttonContainerHeading = "CartReservationComplete__buttonContainerHeading__A9Cvo";
export var column = "CartReservationComplete__column__53Gem";
export var flex = "CartReservationComplete__flex__dWkcP";
export var flexColumn = "CartReservationComplete__flexColumn__vJWol";
export var formContainer = "CartReservationComplete__formContainer__Y30CM";
export var formTitle = "CartReservationComplete__formTitle__Q2634";
export var gap1 = "CartReservationComplete__gap1__Cy6VI";
export var gap2 = "CartReservationComplete__gap2__E1rBF";
export var gap3 = "CartReservationComplete__gap3__OF2qA";
export var gap4 = "CartReservationComplete__gap4__qtc3O";
export var gap5 = "CartReservationComplete__gap5__s5MiU";
export var layout = "CartReservationComplete__layout__eELzN";
export var row = "CartReservationComplete__row__SG5p6";